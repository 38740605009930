// Import components
import HeroSection from '../assets/components/HeroSection';
import AboutUsSection from '../assets/components/AboutUs/AboutUsSection';
import EventsSections from '../assets/components/Events/EventsSections';
import TimelineSection from '../assets/components/Events/Timeline/TimelineSection';
import GallerySection from '../assets/components/GallerySection';
import ContactSection from '../assets/components/ContactSection';

const Landing = ({ programRef, timelineRef, galleryRef, aboutUsRef, contactRef, handleScroll }) => {
  return (
    <div>
        <HeroSection handleScroll={handleScroll} programRef={programRef} />
    </div>
  )
}

export default Landing