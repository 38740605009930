import { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import components
import Navbar from './assets/components/Navbar';
import Landing from './pages/Landing';
import TicketsPage from './pages/TicketsPage'; // Import your TicketsPage component
import PaymentSuccess from './pages/PaymentSuccess';

// Import CSS
import './assets/css/App.css';
import './assets/css/index.css';

function App() {
  const programRef = useRef(null);
  const timelineRef = useRef(null);
  const galleryRef = useRef(null);
  const aboutUsRef = useRef(null);
  const contactRef = useRef(null);

  const handleScroll = (ref) => {
    if (ref) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Router>
      <Navbar
        programRef={programRef}
        timelineRef={timelineRef}
        galleryRef={galleryRef}
        aboutUsRef={aboutUsRef}
        contactRef={contactRef}
        handleScroll={handleScroll}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Landing
              programRef={programRef}
              timelineRef={timelineRef}
              galleryRef={galleryRef}
              aboutUsRef={aboutUsRef}
              contactRef={contactRef}
              handleScroll={handleScroll}
            />
          }
        />
        <Route path="/tickets/:eventId" element={<TicketsPage />} />
        <Route path="/payment_success" element={<PaymentSuccess />} />
      </Routes>
    </Router>
  );
}

export default App;
