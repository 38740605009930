import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

const PaymentSuccess = () => {
    const location = useLocation();
    const sessionId = new URLSearchParams(location.search).get('session_id');
    const [message, setMessage] = useState("Your ticket is being processed. Please check your email shortly.");
    const [isProcessing, setIsProcessing] = useState(false);

    const apiBaseUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

    useEffect(() => {
        const generateTickets = async () => {
            if (sessionId && !localStorage.getItem(`ticketsGenerated_${sessionId}`)) {
                if (isProcessing) return; // Prevent duplicate calls
                setIsProcessing(true);
    
                try {
                    const response = await fetch(`${apiBaseUrl}/api/generate-ticket`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ sessionId, quantity: 3 }), // Pass quantity here (adjust as needed)
                    });
    
                    const data = await response.json();
    
                    if (data.success) {
                        setMessage(`Tickets generated successfully! Check your email for the following IDs: ${data.tickets.join(', ')}`);
                        localStorage.setItem(`ticketsGenerated_${sessionId}`, 'true');
                    } else {
                        setMessage(data.message || "Error generating tickets. Please contact support.");
                    }
                } catch (error) {
                    console.error("Error generating tickets:", error);
                    setMessage("An error occurred. Please try again or contact support.");
                } finally {
                    setIsProcessing(false);
                }
            }
        };
    
        generateTickets();
    }, [sessionId, isProcessing, apiBaseUrl]);

    return (
        <div className="flex flex-col items-center min-h-screen justify-center bg-gray-900 text-white p-6">
            <h1 className="text-3xl font-bold mb-4">Payment Successful!</h1>
            <p aria-live="polite">{message}</p>
            {isProcessing && (
                <div className="mt-4">
                    <div className="spinner-border text-green-500" role="status">
                        <span className="sr-only">Processing...</span>
                    </div>
                </div>
            )}
            <Link to="/" className="mt-6 bg-green-500 text-white px-6 py-2 rounded-lg text-lg">
                Go Back to Home
            </Link>
        </div>
    );
};

export default PaymentSuccess;
