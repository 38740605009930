import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const HeroSection = ({ handleScroll, programRef }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  const handleIframeLoad = () => {
    setIsLoading(false); // Remove loading spinner when iframe is loaded
  };

  return (
    <section className="relative overflow-hidden">
      {/* Inline CSS for mobile responsiveness */}
      <style>
        {`
          .iframe-container {
            height: 100vh;
            position: relative;
            overflow: hidden;
          }

          .iframe-video {
            width: 100%;
            height: 100vh;
            border: none;
          }

          @media (max-width: 768px) {
            .iframe-video {
              height: 100vh;
            }
          }
        `}
      </style>

      <div className="iframe-container">
        {/* Loading Spinner */}
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
          </div>
        )}

        {/* Google Drive Embedded Video */}
        <iframe
          className="iframe-video"
          src="https://drive.google.com/file/d/1kE5tYptEwxXxJTY6XwMa2fb0bK8Iw1OD/preview?"
          allow="autoplay"
          onLoad={handleIframeLoad} // Handle iframe load
        ></iframe>
      </div>

      <div className="absolute bottom-36 h-full w-full items-end justify-center flex">
        <button
          onClick={() => navigate('/tickets/1')}
          className="bg-white text-[#171717] px-5 py-4 rounded-full hover:bg-[#171717] hover:text-white text-xl flex items-center font-semibold gap-2">
          Buy Tickets Now!
          <i className="fi fi-ss-angle-down text-sm mt-2"></i>
        </button>
      </div>
    </section>
  );
};

export default HeroSection;
