import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Flyer from "../assets/imgs/events/FLYER.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const TicketsPage = () => {
    const { eventId } = useParams();
    const [event, setEvent] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [userDetails, setUserDetails] = useState({ name: '', email: '', confirmEmail: '' });
    const [isAdult, setIsAdult] = useState(false);
    const [emailMatch, setEmailMatch] = useState(true);
    const [canProceed, setCanProceed] = useState(false);

    useEffect(() => {
        const fetchEventData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-current-phase`);
                const phaseData = await response.json();

                if (response.ok) {
                    const eventData = {
                        id: eventId,
                        title: 'Frat Party',
                        bannerUrl: Flyer,
                    };

                    setEvent(eventData);
                } else {
                    throw new Error(phaseData.error || "Error fetching phase data.");
                }
            } catch (error) {
                console.error("Error fetching event data:", error);
                setEvent({
                    id: eventId,
                    title: 'Frat Party',
                    bannerUrl: Flyer,
                });
            }
        };

        fetchEventData();
    }, [eventId]);

    const handleQuantityChange = (amount) => {
        setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
    };

    const handleCheckboxChange = () => {
        setIsAdult((prev) => !prev);
    };

    const handlePurchaseClick = () => {
        setShowForm(true);

        setTimeout(() => {
            document.querySelector('.form-container').scrollIntoView({ behavior: 'smooth' });
        }, 200);
    };

    useEffect(() => {
        setEmailMatch(userDetails.email === userDetails.confirmEmail);
        setCanProceed(emailMatch && isAdult && userDetails.name && userDetails.email);
    }, [userDetails, emailMatch, isAdult]);

    const [isProcessing, setIsProcessing] = useState(false); // New state to track processing

    const handlePayment = async () => {
        const stripe = await stripePromise;

        if (canProceed && !isProcessing) {
            setIsProcessing(true); // Lock the process

            try {
                const response = await fetch('https://gridmty.com/backend/api/create-checkout-session', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        title: event.title,
                        quantity: quantity,
                        email: userDetails.email,
                        name: userDetails.name,
                    }),
                });

                const session = await response.json();

                const { error } = await stripe.redirectToCheckout({
                    sessionId: session.id,
                });

                if (error) {
                    console.error('Stripe Checkout error:', error);
                }
            } catch (error) {
                console.error('Error initiating payment:', error);
            } finally {
                setIsProcessing(false); // Unlock the process after completion
            }
        }
    };
  
    

    if (!event) {
        return <div>Loading...</div>;
    }

    return (
        <div className="flex flex-col items-center bg-gray-900 text-white p-6 min-h-screen relative pb-20">
            <div className="w-full max-w-md mb-6">
                <img src={event.bannerUrl} alt={event.title} className="rounded-lg shadow-lg" />
            </div>
            <div className="text-center">
                <h2 className="text-3xl font-bold mb-2">{event.title}</h2>
                <p className="text-xl text-green-400 mb-4">
                    Proceed to checkout to calculate the price!
                </p>
                <div className="flex items-center justify-center gap-4 mb-4">
                    <button onClick={() => handleQuantityChange(-1)} className="text-2xl px-3 py-1 bg-blue-500 rounded-lg">
                        -
                    </button>
                    <span className="text-xl">{quantity}</span>
                    <button onClick={() => handleQuantityChange(1)} className="text-2xl px-3 py-1 bg-blue-500 rounded-lg">
                        +
                    </button>
                </div>
                <button onClick={handlePurchaseClick} className="bg-green-500 text-white px-6 py-2 rounded-lg text-lg">
                    Buy
                </button>
            </div>

            {showForm && (
                <div className="mt-6 bg-gray-800 p-4 rounded-lg shadow-lg w-full max-w-md form-container">
                    <h3 className="text-xl font-semibold mb-4">Enter Your Details</h3>
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={userDetails.name}
                        onChange={handleInputChange}
                        className="w-full mb-3 p-2 rounded bg-gray-700 text-white"
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={userDetails.email}
                        onChange={handleInputChange}
                        className="w-full mb-3 p-2 rounded bg-gray-700 text-white"
                    />
                    <input
                        type="email"
                        name="confirmEmail"
                        placeholder="Confirm Email"
                        value={userDetails.confirmEmail}
                        onChange={handleInputChange}
                        className={`w-full mb-3 p-2 rounded ${emailMatch ? 'bg-gray-700' : 'bg-red-700'} text-white`}
                    />
                    {!emailMatch && <p className="text-red-500 text-sm mb-2">Emails do not match.</p>}
                    
                    <div className="flex items-center mb-4">
                        <input
                            type="checkbox"
                            checked={isAdult}
                            onChange={handleCheckboxChange}
                            className="mr-2"
                        />
                        <label className="text-sm">I confirm I am 18 years or older.</label>
                    </div>

                    <button
                        className={`px-4 py-2 rounded-lg w-full ${
                            canProceed ? 'bg-blue-500 text-white' : 'bg-gray-500 text-gray-300 cursor-not-allowed'
                        }`}
                        disabled={!canProceed}
                        onClick={handlePayment}
                    >
                        Confirm Purchase
                    </button>
                </div>
            )}

            {/* Sticky Footer */}
            <footer className="fixed bottom-0 left-0 w-full bg-gray-800 py-4 flex justify-center gap-4 z-10">
                <a
                    href="https://www.instagram.com/grid.mty?igsh=dWEydGpsdjFhc202"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white bg-pink-600 p-3 rounded-full"
                >
                    <FontAwesomeIcon icon={faInstagram} size="lg" />
                </a>
                <a
                    href="https://chat.whatsapp.com/LI9sUsFn4GM1DTx8szYNiM"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white bg-green-600 p-3 rounded-full"
                >
                    <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                </a>
            </footer>
        </div>
    );
};

export default TicketsPage;
