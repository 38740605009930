import Logo from '../imgs/logos/logo_main.png';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const Navbar = ({ handleScroll, programRef, timelineRef, galleryRef, aboutUsRef, contactRef }) => {
    const navigate = useNavigate();

    const [activeOption, setActiveOption] = useState(0);
    const [openMenu, setOpenMenu] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(null); // Track which dropdown is open

    const links = [
        { name: 'HOME', link: '/', ref: null },
        { name: 'TICKETS', link: '/tickets/1', ref: null },
    ];

    const handleActiveOption = (index) => {
        setActiveOption(index);
    };

    const handleMenuButton = () => {
        setOpenMenu(!openMenu);
    };

    const handleDropdown = (index) => {
        setDropdownOpen(dropdownOpen === index ? null : index); // Toggle the dropdown
    };

    // Update active section based on scroll position
    useEffect(() => {
        const sectionRefs = [null, programRef, timelineRef, galleryRef, aboutUsRef, contactRef];

        const handleScrollUpdate = () => {
            const offset = 100; // Adjust this offset to trigger the section change earlier or later
            sectionRefs.forEach((ref, index) => {
                if (ref && ref.current) {
                    const sectionTop = ref.current.getBoundingClientRect().top;
                    if (sectionTop <= offset && sectionTop >= -ref.current.offsetHeight + offset) {
                        setActiveOption(index);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScrollUpdate);
        return () => {
            window.removeEventListener('scroll', handleScrollUpdate);
        };
    }, [programRef, timelineRef, galleryRef, aboutUsRef, contactRef]);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest(".group")) {
                setDropdownOpen(null);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const displayMobileLinks = links.map((link, index) => (
        <div key={index} className='py-2'>
            <a
                href={link.link ? link.link : ''}
                className={`nav-item flex items-center ${index === activeOption ? 'text-white underline underline-offset-8' : 'text-zinc-200 hover:underline underline-offset-8'}`}
                onClick={() => handleActiveOption(index)}
            >
                {link.name}
            </a>
        </div>
    ));

    const displayLinks = links.map((link, index) => {
        if (link.dropdown) {
            return (
                <div className="relative group" key={index}>
                    <button
                        onClick={() => handleDropdown(index)}
                        className={`nav-item flex items-center font-bold gap-2 ${index === activeOption ? 'text-white' : 'text-zinc-200'}`}
                    >
                        <span
                            className={`${index === activeOption ? 'underline underline-offset-8' : 'hover:underline underline-offset-8'}`}>
                            {link.name}
                        </span>
                        <i className="fi fi-ss-angle-down text-[0.60rem] mt-1 no-underline"></i>
                    </button>
                    {/* Dropdown menu */}
                    <div
                        className={`absolute ${dropdownOpen === index ? 'block' : 'hidden'} bg-black text-white shadow-md p-2 mt-1 text-nowrap`}
                    >
                        {link.dropdown.map((option, i) => (
                            option.ref ? (
                                <button key={i} onClick={() => handleScroll(option.ref)} className="block px-4 py-2 hover:bg-gray-800 cursor-pointer">{option.name}</button>
                            ) : (
                                <a key={i} href={option.link} className="block px-4 py-2 hover:bg-gray-800">{option.name}</a>
                            )
                        ))}
                    </div>
                </div>
            );
        } else {
            return (
                <a
                    key={index}
                    href={link.link ? link.link : null}
                    className={`text-zinc-200 font-bold hover:text-white hover:underline underline-offset-8 transition duration-1000 ease-in-out cursor-pointer ${
                        index === activeOption ? 'text-white underline' : ''
                    }`}
                    onClick={() => {
                        handleActiveOption(index);
                        handleScroll(link.ref ? link.ref : null);
                    }}
                >
                    {link.name}
                </a>
            );
        }
    });

    return (
        <div className="bg-[#171717] sticky top-0 z-[1000]">
            <div className="mx-auto max-w-8xl px-2 sm:px-6 lg:px-8">
                <div className="relative py-14 flex items-center justify-between h-16">
                    <a onClick={() => navigate('/')} className="cursor-pointer">
                        <img className='w-52' src={Logo} alt="F+F" />
                    </a>
                    <div className="hidden lg:block sm:ml-6">
                        <div className="flex space-x-10">
                            {displayLinks}
                        </div>
                    </div>
                    <div className="inset-y-0 left-0 flex items-center lg:hidden">
                        <button onClick={handleMenuButton} type="button" id="mobile-menu-button" className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus-white" aria-controls="mobile-menu" aria-expanded="false">
                            <i className="fi fi-rr-menu-burger"></i>
                        </button>
                    </div>
                </div>
            </div>
            {openMenu && (
                <div className="md:hidden" id="mobile-menu">
                    <div className="space-y-1 px-2 pb-3 pt-2 text-center justify-items-center">
                        {displayMobileLinks}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Navbar;
